<template>
  <div class="sLoader">
    <v-progress-circular
      :size="100"
      :width="10"
      color="primary"
      indeterminate
    />
  </div>
</template>

<script>
export default {
  name: "PageLoader",
};
</script>

<style scoped>
.sLoader {
  text-align: center;
  margin-top: 100px;
}
</style>
